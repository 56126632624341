import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import meetJohnHero from '../images/meet-john/meet-john-hero.webp'
import meetJohn1 from '../images/meet-john/meet-john-1.webp'
import meetJohn2 from '../images/meet-john/meet-john-2.webp'
import meetJohn3 from '../images/meet-john/meet-john-3.webp'
import meetJohn4 from '../images/meet-john/meet-john-4.webp'
import meetJohn5 from '../images/meet-john/meet-john-5.webp'
import meetJohn6 from '../images/meet-john/meet-john-6.webp'
import meetJohn7 from '../images/meet-john/meet-john-7.webp'
import meetJohn8 from '../images/meet-john/meet-john-8.webp'
import meetJohn9 from '../images/meet-john/meet-john-9.webp'
import meetJohn10 from '../images/meet-john/meet-john-10.webp'
import meetJohn11 from '../images/meet-john/meet-john-11.webp'
import meetJohn12 from '../images/meet-john/meet-john-12.webp'
import meetJohn13 from '../images/meet-john/meet-john-13.webp'

const MeetJohnPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Meet John" />

    <div className="flex flex-col-reverse lg:flex-col relative bg-gray-50">
      <div className="flex-none mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div className="lg:w-1/2 pl-4 lg:pl-0 pr-4 lg:pr-8">
          <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline">Meet John</span>
          </h1>
          <p className="max-w-md mx-auto text-3xl text-gray-500 sm:text-3xl md:max-w-3xl">
            Councilman Goncalves has been a resident of Providence as a Fox Point and East Side community member for over 25 years. John is a passionate educator and DEI Coordinator at the Wheeler School in Providence and a community advocate who has worked to advance issues on education, environmental justice, and neighborhood quality of life.
          </p>
          <p className="max-w-md mx-auto text-3xl text-gray-500 sm:text-3xl md:max-w-3xl">
            Now, John is running for Congress in the 2023 Special Election for Rhode Island's First Congressional District to continue his advocacy for the Ocean State's working families in Washington.
          </p>
        </div>
      </div>
      <div className="flex-none relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img
          className="absolute inset-0 w-full h-full object-cover object-left"
          src={meetJohnHero}
          alt=""
        />
      </div>
    </div>

    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto pt-0 pb-8 px-4 xl:px-0">
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-0">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <div className="lg:grid lg:grid-cols-2 gap-8">
              <div className="relative lg:row-start-1 lg:col-start-1 mb-8 xl:mb-0">
                <img src={meetJohn1} />
              </div>
              <div className="relative lg:row-start-1 lg:col-start-2">
                <img src={meetJohn2} />
              </div>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-1 gap-8">
              <img className="mb-8 xl:mb-0" src={meetJohn3} />
              <img className="mb-8 xl:mb-0" src={meetJohn5} />
              <img className="mb-8 xl:mb-0" src={meetJohn6} />
              <img className="mb-8 xl:mb-0" src={meetJohn4} />
              <img className="mb-8 xl:mb-0" src={meetJohn9} />
              <img className="mb-8 xl:mb-0" src={meetJohn7} />
              <img className="mb-8 xl:mb-0" src={meetJohn8} />
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="pr-0 lg:pr-8 text-base max-w-prose mx-auto lg:max-w-none text-3xl text-gray-500">
              <p className="text-3xl">
                Goncalves attended Providence Public Schools, is a Wheeler School graduate, and graduated with a B.A. and a Master's degree from Brown University.
              </p>
              <p className="text-3xl">
                Councilman John Goncalves has been an active and effective legislator and the lead sponsor and author of dozens of pieces of passed legislation in the City of Providence. He currently serves as Chairman of the Providence City Council's Committee on State Legislative Affairs, Chairman of the Committee on City Property, and Chairman of the Committee on Claims and Pending Suits.
              </p>
              <p className="text-3xl">
                Additionally, Councilman Goncalves has been <strong>a strong, consistent voice on matters across the most actively developing Ward in the City of Providence, Ward 1, which includes Downtown, The I-195 Land, and the East Side of Providence.</strong>
              </p>
              <p className="text-3xl">
                Professionally, Goncalves is a passionate educator and a community advocate who has worked to <strong>advance issues on education, environmental justice, and neighborhood quality of life</strong>. Previously, John served as a board member of the Fox Point Neighborhood Association and the Brown Alumni Association Board of Governors. He was also named <em>Providence Monthly's Who To Watch in 2020</em> for spearheading a Providence Coalition of Neighborhood Associations that included representation from more than 19 neighborhood associations working collaboratively to tackle common neighborhood challenges and citywide issues.
              </p>
              <p className="text-3xl">
                John also served as a teacher for several years in Minnesota, where he was a faculty leader. <strong>Outside of being an educator, John has been involved in many political campaigns, including roles as Chair of the Minnesota Senate District 60 Committee and Strategy Director in key City Council, Mayoral, Attorney General, and Senate races.</strong> John previously served as an elected board member of the Marcy-Holmes Neighborhood Association, the oldest and largest neighborhood association in Minneapolis.    
              </p>
              <p className="text-3xl">
                Councilman Goncalves currently lives with his partner in Fox Point. 
              </p>
              <img className="mb-8" src={meetJohn12} />
              <img className="mb-8" src={meetJohn13} />
              <img className="mb-8" src={meetJohn10} />
              <img className="mb-8" src={meetJohn11} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default MeetJohnPage
